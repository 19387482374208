// /*
//     uygur.io
//     Uygur Kıran - @u7k - 2025
//  */

// import { useState, useEffect } from 'react'
// import PocketBase from 'pocketbase'

export const ClientVersion = "2025.1.15"
// export const ApiHost = "http://localhost:8080"

// const pb = new PocketBase(ApiHost);

// /**
//  * Custom hook to fetch a list of apps from the 'apps' collection.
//  *
//  * @returns {Object} An object containing the following properties:
//  * - apps {Array}: The list of apps.
//  * - loading {boolean}: A boolean indicating if the data is still being loaded.
//  * - error {Error|null}: An error object if an error occurred during fetching, otherwise null.
//  */
// export function useAppsList() {
//   const [apps, setApps] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     async function fetchApps() {
//       try {
//         const records = await pb.collection('apps').getList(1, 50, {
//           fields: 'name,icon,short_description,platform,is_available',
//             //   sort: 'platform',
//         });
//         setApps(records.items);
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchApps();
//   }, []);

//   return { apps, loading, error };
// } //: useAppsList