/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useState, useEffect, useContext, useCallback, createContext, useRef } from "react"
import { Helmet } from "react-helmet"

import MenuBar from "./MenuBar"
import DropAlert from "./DropAlert"
import Desktop from "./Desktop"
import Window from "./Window"
import Dialog from "./Dialog"

function useMediaQuery(query) {
  const [matches, setMatches] = useState(
    typeof window !== 'undefined' && window.matchMedia(query).matches
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const mediaQueryList = window.matchMedia(query);
    const listener = (event) => {
      setMatches(event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, [query]);

  return matches;
} //: useMediaQuery

const FinderContext = createContext()

const FinderState = {
  window: {
    isWindowFullscreened: false,
    isWindowOpened: true,
    windowTitle: "Untitled",
  },
  dropAlert: {
    isVisible: false,
    title: null,
    subtitle: null,
    type: "info",
    icon: null,
    duration: null,
  },
  dialog: {
    isVisible: true,
    title: null,
    content: null,
    width: "360px",
    height: "auto",
    primaryButtonLabel: "OK",
  },
};

export function useFinderContext() {
  return useContext(FinderContext);
}

export default function Finder({ title, children }) {
  const isMediumScreen = useMediaQuery("(min-width: 768px)");

  const [finderState, setFinderState] = useState({
    ...FinderState,
    window: {
      ...FinderState.window,
      windowTitle: title ?? FinderState.window.windowTitle,
    },
  });

  const updateFinderState = (updates) => {
    setFinderState((prevState) => ({
      ...prevState,
      window: {
        ...prevState.window,
        ...updates,
      },
    }));
  };

  const alertTimeoutRef = useRef(null);

  const hideAlert = useCallback(() => {
    setFinderState((prevState) => ({
      ...prevState,
      dropAlert: {
        ...prevState.dropAlert,
        isVisible: false,
      },
    }));
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }
  }, []);

  const hideDialog = useCallback(() => {
    setFinderState((prevState) => ({
      ...prevState,
      dialog: {
        ...prevState.dialog,
        isVisible: false,
      },
    }));
  }, []);

  const showAlert = useCallback(
    ({ title, subtitle, type = "info", icon, duration }) => {
      if (alertTimeoutRef.current) {
        clearTimeout(alertTimeoutRef.current);
      }

      const displayNewAlert = () => {
        setFinderState((prevState) => ({
          ...prevState,
          dropAlert: {
            isVisible: true,
            title,
            subtitle,
            type,
            icon,
            duration,
          },
        }));

        alertTimeoutRef.current = setTimeout(() => {
          hideAlert();
        }, Math.min(duration ?? 1.2, 3) * 1000);
      };

      if (finderState.dropAlert.isVisible) {
        setFinderState((prevState) => ({
          ...prevState,
          dropAlert: {
            ...prevState.dropAlert,
            isVisible: false,
          },
        }));
        setTimeout(displayNewAlert, 50);
      } else {
        displayNewAlert();
      }
    },
    [finderState.dropAlert.isVisible, hideAlert]
  );

  const showDialog = useCallback(
    ({ title, content, width, height, primaryButtonLabel = "OK" }) => {
      setFinderState((prevState) => ({
        ...prevState,
        dialog: {
          isVisible: true,
          title: title,
          content,
          width: width ?? "400px",
          height: height ?? "auto",
          primaryButtonLabel,
        },
      }));
    },
    []
  );

  return (
    <FinderContext.Provider
      value={{
        finderState,
        updateFinderState,
        showAlert,
        showDialog,
        isMediumScreen,
      }}
    >
      <Helmet>
        <title>{title} *io.Uygur</title>
      </Helmet>
      <div>
        <MenuBar />
        <DropAlert alert={finderState.dropAlert} dismiss={hideAlert} />
        <Dialog dialog={finderState.dialog} dismiss={hideDialog} />
        <Desktop>
          <Window>{children}</Window>
        </Desktop>
      </div>
    </FinderContext.Provider>
  );
}
