/*
    uygur.io
    Uygur Kıran - @u7k - 2023
 */

import { useState, useEffect } from "react";
import { useFinderContext } from "../finder/Finder";
import TitleView from "../components/TitleView.jsx";

export default function Contact() {
  const { showAlert } = useFinderContext();

  function copyEmailToClipboard() {
    const email = "kiran@uygur.io";
    navigator.clipboard
      .writeText(email)
      .then(() => {
        showAlert({
          title: "📑 Email address copied to clipboard.",
        });
      })
      .catch(() => {
        // If copying fails, open mailto: link in a new tab.
        window.open(`mailto:${email}`, "_blank");
      });
  }

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []);

  const getUTCTime = () => {
    const utcOffset = 3; // UTC +3
    const utcTime = new Date(currentTime.getTime() + utcOffset * 60 * 60 * 1000);
    let hours = utcTime.getUTCHours();
    const minutes = utcTime.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return strTime;
  };

  return (
    <div className="w-full h-full p-4">
      <TitleView title="Contact" />

      <div className="font-sans text-sm mt-4">
        <ContactItem icon="person">Uygur Kıran</ContactItem>

        <ContactItem icon="mail">
          <span className="underline a-style" onClick={copyEmailToClipboard}>
            kiran@uygur.io
          </span>
        </ContactItem>

        <ContactItem icon="home">Trabzon & Istanbul, Turkey</ContactItem>

        <ContactItem icon="watch" alt="Watch icon for the timezone">
          UTC +3h ({getUTCTime()})
        </ContactItem>

        {/* 
        <ContactItem icon="twitter">
          <a className="underline" href="https://twitter.com/lsbunuel" target="_blank" rel="noopener noreferrer">@lsbunuel</a>
        </ContactItem> 
        */}
      </div>
    </div>
  );
} //: Contact

function ContactItem({ icon, alt, children }) {
  return (
    <div className="flex items-center mb-3">
      <img
        className="w-[15px] h-[15px] my-auto mr-[9px] ml-[3px]"
        src={`/assets/icons/${icon}.svg`}
        alt={alt ?? `${icon.charAt(0).toUpperCase() + icon.slice(1)} icon`}
      />
      <div>{children}</div>
    </div>
  );
} //: ContactItem
