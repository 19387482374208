/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import Help from "./help";
import Neofetch from "./neofetch";
import { ClientVersion } from "../../networking";

export function getOutput(input) {
  const [cmd, ...args] = input.trim().split(/\s+/);

  switch (cmd.toLowerCase()) {
    case "clear":
      return null;
    case "help":
      return <Help />;
    case "info":
        return showInfo();
    case "neofetch":
    case "ufetch":
      return <Neofetch />;
    case "date":
      return formatDate();
    case "generate":
      return generatePassword();
    case "kanye":
      return showKanyeQuote();
    case "cal":
      return showCalendar();
    case "hello":
      return "hi";
    case "bye":
      return "Goodbye!";
    case "shutdown":
      window.location.href = "/0";
      return "Shutting down...";
    case "":
      return "";
    default:
      return `command not found: ${cmd}`;
  }
} //: getOutput

function showInfo() {
  return (
    <div className="text-white">
      <p className="">> ioTerm by uygur kıran</p>
      <p className="text-xs">powered by react and tailwind.</p>
      <p className="text-xs">v. {ClientVersion}</p>
    </div>
  );
}

function formatDate() {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  return new Date().toLocaleDateString("en-US", options);
}

function generatePassword() {
  const x = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "q", "w", "e", "r", "t", "y", "u", "o", "p", "a", "s", "d", "f", "g", "h", "j", "k", "l", "i", "z", "x", "c", "v", "b", "n", "m", "Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "A", "S", "D", "F", "G", "H", "J", "K", "L", "Z", "X", "C", "V", "B", "N", "M"];
  var password = "";

  while (password.length < 20) {
    if (password.length > 0 && password.length % 5 == 0) {
      password += "-";
    } else {
      password += x[Math.floor(Math.random() * x.length)];
    }
  }
  return (
    <div>
      <span className="text-yellow">Password generated:</span>
      <span className="text-white"> {password.substring(1)}</span>
    </div>
  )
} //: generatePassword

function showKanyeQuote() {
  const q = [
    "One good girl is worth a thousand bitches.",
    "My greatest pain in life is that I will never be able to see myself perform live.",
    "I still think I am the greatest.",
    "My greatest award is what I’m about to do.",
    "I feel like I’m too busy writing history to read it.",
    "I’m on the pursuit of awesomeness, excellence is the bare minimum.",
    "You can’t look at a glass half full or empty if it’s overflowing.",
    "If I got any cooler I would freeze to death.",
    "I'm my favorite rapper.",
    "We came into a broken world. And we're the cleanup crew.",
    "I am Warhol. I am the No. 1 most impactful artist of our generation. I am Shakespeare in the flesh.",
    "I love sleep; it's my favorite.",
    "Art is to be free. Design is to fix.",
  ];
  return q[Math.floor(Math.random() * q.length)];
} //: showKanyeQuote

function showCalendar() {
  const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  const date = new Date();
  const today = date.getDate();
  const monthStr = months[date.getMonth()];
  const year = date.getFullYear();
  const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();
  const firstDayIdx = new Date(year, date.getMonth(), 1).getDay();

  const title = `${monthStr} ${year}`;

  const calendar = [];
  let row = [];

  // Add empty cells for days before the first day of the month
  for (let i = 0; i < firstDayIdx; i++) {
    row.push(<td key={`empty-${i}`} className="text-right p-1">&nbsp;</td>);
  }

  // Add days of the month
  for (let i = 1; i <= lastDay; i++) {
    if (row.length === 7) {
      calendar.push(<tr key={`row-${calendar.length}`}>{row}</tr>);
      row = [];
    }
    row.push(
      <td key={i} className={`text-right p-1 ${i === today ? '!bg-white !bg-clip-content !text-black' : ''}`}>
        {i}
      </td>
    );
  }

  // Add the last row if it has any cells
  if (row.length > 0) {
    calendar.push(<tr key={`row-${calendar.length}`}>{row}</tr>);
  }

  return (
    <div className="text-white leading-4">
      <table className="table-auto w-auto mr-auto">
        <thead>
          <tr>
            <th colSpan={7} className="text-center font-normal pt-1">{title}</th>
          </tr>
          <tr>
            {days.map(day => (
              <th key={day} className="text-right font-normal p-1">{day}</th>
            ))}
          </tr>
        </thead>
        <tbody >
          {calendar}
        </tbody>
      </table>
    </div>
  );
} //: showCalendar