/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useState, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

export default function DropAlert({ alert, dismiss }) {
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMediumScreen(window.innerWidth >= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!alert) {
        return null;
    }

    const animatedVariants = {
        hidden: 
        isMediumScreen 
            ? { opacity: 0, x: 50, scale: 0.95, filter: 'blur(10px)' }
            : { opacity: 0, y: -30, scale: 0.95, filter: 'blur(10px)' },
        visible: { 
            opacity: 1, 
            y: 0,
            x: 0,
            scale: 1,
            filter: "blur(0px)",
            transition: {
                type: "easeIn",
                duration: 0.3,
                ease: [0.25, 0.1, 0.25, 1.0] 
            }
        },
        exit: isMediumScreen
            ?  {
                opacity: 0,
                x: 50,
                scale: 0.95,
                filter: 'blur(16px)',
                transition: {
                    duration: 0.7,
                    filter: {
                        delay: 0.1,
                        duration: 0.5
                    }
                }
            } 
            : {
            opacity: 0,
            y: -20,
            filter: 'blur(16px)',
            transition: {
                duration: 0.7,
                filter: {
                    delay: 0.1,
                    duration: 0.5
                }
            }
        }
    } //: animatedVariants

    return (
        <AnimatePresence>
            {alert.isVisible && (
                <div className="
                    fixed inset-x-0 
                    top-[--menu-bar-height] 
                    flex 
                    justify-center md:justify-end
                    z-[2147483647]
                ">
                    <motion.div
                        variants={animatedVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        onClick={dismiss}
                        className={`
                            bg-white border-2 border-black shadow-retro
                            px-4 py-3 rounded
                            flex items-start text-center
                            mt-1 md:mt-2 md:mr-3
                            w-auto
                            select-none
                    `}>
                        {alert.icon && (
                            <div className="mr-3 mt-1 flex-shrink-0">
                                <img src={alert.icon} alt="Alert icon" className="w-6 h-6" />
                            </div>
                        )}
                        <div className="flex-grow min-w-0">
                            <div className="font-bold text-balance">{alert.title}</div>
                            {alert.subtitle && <div className="text-sm text-gray-600 mt-1 break-words">{alert.subtitle}</div>}
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}