/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

export function lightenColor(color, amount) {
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    console.warn('Invalid color format. Using original color.')
    return color
  }

  const num = parseInt(color.slice(1), 16)
  const r = Math.min(255, ((num >> 16) & 0xFF) + amount)
  const g = Math.min(255, ((num >> 8) & 0xFF) + amount)
  const b = Math.min(255, (num & 0xFF) + amount)

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
} //: lightenColor