/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useRouteError } from "react-router-dom"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TitleView from "../components/TitleView.jsx"

export default function Error() {
    const error = useRouteError();
    const [countdown, setCountdown] = useState(3);
    const navigate = useNavigate();

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            navigate('/');
        }
    }, [countdown, navigate]);

    const getDots = (count) => {
        if (count < 1) return '...';
        return '.'.repeat(4 - count);
    }

    return (
        <div className="
            w-full h-full
            p-4
        ">           
            <TitleView title={`🤦🏻 [${error.status ?? "000"}]`} />

            <div className="mt-5 font-sans">
                <p className="text-4xl font-bold mb-2">
                    {error.statusText ?? error.message ?? "Unknown error"}
                </p>

                <p className="text-sm mb-4">
                    You're being directed to the <a href="/" className="text-blue-500 underline hover:no-underline font-bold">home page</a> in {countdown}{getDots(countdown)}
                </p>
            </div>
        </div>
    )
} //: Error