/*
    uygur.io
    Uygur Kıran - @u7k - 2025
 */

import { motion, AnimatePresence } from "framer-motion";

export default function Dialog({ dialog, dismiss }) {
  if (!dialog || !dialog.content) {
    return null;
  }

  const dialogVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
      y: -10,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: 10,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <AnimatePresence>
      {dialog.isVisible && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="
              fixed inset-0
              min-h-screen w-full
              flex items-center justify-center
            bg-black/20 backdrop-blur-[2px] z-[2147483646]
            "
            onClick={dismiss}
          >
            {/* Dialog */}
            <motion.div
              variants={dialogVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="mb-10 z-[2147483647] "
              style={{ width: dialog.width, height: dialog.height }}
            >
              <div
                className="
                bg-primary-bg       
                  border-2 border-black
                  shadow-retro
                  rounded
                  overflow-hidden
                  pt-3 pb-1
                  px-3
                "
              >
                
                { 
                  dialog.title &&
                  <h1 className="text-[1.3rem] text-center pb-2">{dialog.title}</h1>
                }

                <div className="px-[0.1rem]">{dialog.content}</div>

                {/* <hr /> */}

                <div className="flex justify-center my-3">
                  <button onClick={dismiss} className="main-button px-14">
                    {dialog.primaryButtonLabel}
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
