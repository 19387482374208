/*
    uygur.io
    Uygur Kıran - @u7k - 2022
 */

import React from "react"
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
  useParams
} from "react-router-dom"

// import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import "./styles/globals.css"

import Finder from "./finder/Finder"
import Error from "./pages/Error"
import Home from "./pages/Home"
import Works from "./pages/works/Works"
import AppDetail from "./pages/works/AppDetail.jsx"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Terminal from "./pages/terminal/Terminal"
import Clock from "./pages/clock/Clock.jsx"
import Shutdown from "./finder/Shutdown.jsx"
// import Blog from "./pages/blog/Blog"
// import Article from "./pages/blog/Article"

/**********************
 * Router *
**********************/

const router = createBrowserRouter([
  {
    path: "/", 
    element: <Finder title="Home"><Home /></Finder>,
    errorElement: <Finder title="Error"><Error /></Finder>
  },
  {
    path: "/works",
    element: <Finder title={"Works"}><Works /></Finder>
  },
  {
    path: "/works/apps/:appName",
    element: <Finder title={"Apps"}><AppDetail /></Finder>,
    errorElement: <Finder title="Error"><Error /></Finder>
  },
  {
    path: "/about", exact: true, 
    element: <Finder title="About"><About /></Finder>
  },
  {
    path: "/contact", exact: true, 
    element: <Finder title="Contact"><Contact /></Finder>
  },
  {
    path: "/terminal", exact: true, 
    element: <Finder title="Terminal"><Terminal /></Finder>
  },
  {
    path: "/clock", exact: true,
    element: <Finder title="World Clock"><Clock /></Finder>
  },
  {
    path: "/0", exact: true,
    element: <Shutdown />
  }
  // {
  //   path: "/blog",
  //   element: <Finder title="Blog"><Blog /></Finder>
  // },
  // {
  //   path: "/blog/:slug",
  //   element: <Finder title="Blog"><Article /></Finder>
  // },
]);

/**********************
 * Root *
**********************/

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
       <RouterProvider router={router} />
  // </React.StrictMode>
)

