export const appsData = [

  // iOS
  { 
    name: "visiony", 
    icon: "/assets/works/apps/visiony/icon.png", 
    shortDescription: "AI Object Detection", 
    platform: "iOS", 
    isAvailable: false 
  },
  { 
    name: "Kahve Bul", 
    icon: "/assets/works/apps/kahvebul/icon.png", 
    shortDescription: "Find Nearby Coffee Shops", 
    platform: "iOS", 
    isAvailable: false
  },
  { 
    name: "Lazca Sozluk", 
    icon: "/assets/works/apps/laz-dic/icon.png", 
    shortDescription: "Laz Dictionary", 
    platform: "iOS", 
    isAvailable: true 
  },
  { 
    name: "3hits", 
    icon: "/assets/works/apps/3hits/icon.png", 
    shortDescription: "Daily Planner", 
    platform: "iOS", 
    isAvailable: false
  },

  // watchOS
  { 
    name: "Allegretto", 
    icon: "/assets/works/apps/allegretto/icon.png", 
    shortDescription: "Metronome", 
    platform: "watchOS", 
    isAvailable: false 
  },
  { 
    name: "wwiki", 
    icon: "/assets/works/apps/wwiki/icon.png", 
    shortDescription: "Search Wikipedia Articles", 
    platform: "watchOS", 
    isAvailable: true 
  },
  { 
    name: "Istanbul Trafik", 
    icon: "/assets/works/apps/ist-trafik/icon.png", 
    shortDescription: "Traffic Density Tracking", 
    platform: "watchOS", 
    isAvailable: true 
  },

  // macOS
  { 
    name: "babydoll", 
    icon: "/assets/works/apps/babydoll/icon.png", 
    shortDescription: "Audio Plugin", 
    platform: "macOS" , 
    isAvailable: true 
  }

]
