/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

export default function TitleView({ title, children }) {
  return (
    <div
      className="flex justify-between items-center mb-3 pb-2 border-b-2 border-gray-700"
      // Some optical alignment to make the bottom border extend beyond the container on the leading side.
      style={{ paddingLeft: "1.2px", marginLeft: "-1.2px" }}
    >
      <div className="text-xl font-bold">{title ?? children}</div>
    </div>
  );
} //: TitleView
