export const worksData = [
    {
      tintColor: "#8FE19F",
      title: "Fujibas (by Fujifilm)",
      subtitle: "Jan 2022 - Jun 2023",
      description: "",
      url: "/works/fujibas",
      tags: [{ title: "Fujifilm", tintColor:"#08A577" }, { title: "iOS" }],
      bullets: [
        "Revamped the old app and elevated the user experience, resulting in a crash rate of zero, high customer retention, and excellent store ratings.",
        "Created the new Fujibas iOS app from scratch using SwiftUI, concurrency APIs, and Combine (v3.0.0-).",
        "Optimized app performance and stability by reducing dependencies and seamlessly integrating AWS (Amplify), and Firebase.",
        "Contributed to the design and usability of the Fujibas API.",
      ],
    },
    {
      tintColor: "#F7CCB2",
      title: "cereblo",
      subtitle: "Jan 2020 - Jan 2021",
      description: "iOS app for Turkish Tweet Sentiment analysis.",
      url: "/works/cereblo",
      tags: [{ title: "Custom model" }, { title: "iOS" }],
      bullets: [
        "Assembled training data, and built ML models. (Swift & CreateML)",
        "Developed a custom data crawler for collecting Twitter data.",
        "Implemented a cross platform iOS app with a purpose-built twitter client, data management, charts & internal analytics.",
      ],
    },
    {
      tintColor: "#A2BFD9",
      title: "babydoll audio plugin",
      subtitle: "Jan 2021",
      description: "An audio processor plugin for the most popular DAWs: Logic Pro X and Ableton.",
      externalUrl: "https://github.com/u7k/babydoll",
      tags: [{ title: "VST & AU plugins", tintColor:"#AF52DE" }, { title: "macOS" }],
      bullets: ["Programmed audio analyzers, effects, filters and GUI in C++."],
    },
  ]