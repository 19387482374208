/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [visibleHelloLetters, setVisibleHelloLetters] = useState(0);
  const helloText = "Hello.";

  useEffect(() => {
    console.log("Hey there! Hope your day is going great! 🌟");
    console.info("I'm an iOS developer based in Turkey.");

    const timer = setInterval(() => {
      setVisibleHelloLetters((prev) =>
        prev < helloText.length ? prev + 1 : prev
      );
    }, 200); // Add new char every 200 ms.
    return () => clearInterval(timer);
  }, []);

  const navigate = useNavigate();

  return (
    <div
      className="
            window-medium 
            px-[1.875rem] py-[1.56rem]
            text-left
            resize-none
        "
    >
      <div className="relative mb-[0.5rem]">
        <h1 className="text-[3.6rem] text-white absolute top-0 left-0">
          {helloText}
        </h1>
        <h1 className="text-[3.6rem] relative">
          {helloText.split("").map((letter, index) => (
            <span
              key={index}
              className="inline-block"
              style={{
                opacity: index < visibleHelloLetters ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
            >
              {letter}
            </span>
          ))}
        </h1>
      </div>
      <div className="">
        <p className="text-xl mb-[0.4rem]">
          I'm an iOS developer based in Turkey 🇹🇷
        </p>
        <p className="text-md font-sans text-secondary-label leading-6">
          Passionate about creating intuitive and efficient mobile experiences.
          <br />
          With a keen eye for design and a love for clean code.
        </p>
      </div>

      <div className="mt-[2rem] mb-[3rem] flex flex-col space-y-3 items-start">
        <button className="main-button" onClick={() => navigate("/about")}>
          about me >
        </button>
        <button className="main-button" onClick={() => navigate("/works")}>
          works >
        </button>
        <button
          className="main-button"
          onClick={() => window.open("https://github.com/u7k", "_blank")}
        >
          github/u7k 🔗
        </button>
      </div>
    </div>
  );
} //: Home