export const appsDetailData = [
  
  // iOS
  { 
    name: "visiony", 
    icon: "/assets/works/apps/visiony/icon.png", 
    shortDescription: "AI Object Detection", 
    platform: "iOS", 
    isAvailable: false,
    description: "Visiony is an experimental mobile AI application that instantly detects the objects that the camera is directed. Visiony enables people to identify objects around easily, providing voice feedback.",
    tags: "iPhone, iOS, application, ai, object, detection, arview, camera",
    url: "https://apps.apple.com/us/app/visiony/id1559351932",
    iphoneScreenshots: [
      "/assets/works/apps/visiony/1.png",
      "/assets/works/apps/visiony/2.png",
      "/assets/works/apps/visiony/3.png",
    ],
  },
  { 
    name: "Kahve Bul", 
    icon: "/assets/works/apps/kahvebul/icon.png", 
    shortDescription: "Find Nearby Coffee Shops", 
    platform: "iOS", 
    isAvailable: false,
    description: "(available only in Turkish) \nKahve Bul directs coffee-lovers quickly to the closest coffee shops. It shows all the coffee places around you, informs you about them and provides directions.",
    tags: "iPhone, iOS, application",
    url: null,
    iphoneScreenshots: [
      "/assets/works/apps/kahvebul/1.png",
      "/assets/works/apps/kahvebul/2.png",
      "/assets/works/apps/kahvebul/3.png",
    ],
  },
  { 
    name: "Lazca Sozluk", 
    icon: "/assets/works/apps/laz-dic/icon.png", 
    shortDescription: "Laz Dictionary", 
    platform: "iOS", 
    isAvailable: true,
    description: "Laz Dictionary is a free app optimized for iPhone and iPad. It offers rich content and experience with thousands of trusted definitions and example sentences, as well as features such as favorite words and suggestions.—and it works offline!",
    tags:
      "iPhone, iOS, application, lazca, türkçe, sözlük, dictionary, kaynak, laz, lazuri, sözcükler, nenapuna, dil, kelimeler, nena",
    url: "https://apps.apple.com/us/app/lazca-türkçe-sözlük/id1542026385",
    iphoneScreenshots: [
      "/assets/works/apps/laz-dic/ss1.png",
      "/assets/works/apps/laz-dic/ss2.png",
      "/assets/works/apps/laz-dic/ss3.png",
      "/assets/works/apps/laz-dic/ss4.png",
      "/assets/works/apps/laz-dic/ss5.png",
      "/assets/works/apps/laz-dic/ss6.png",
    ],
    ipadScreenshots: [
      "/assets/works/apps/laz-dic/pad1.png",
      "/assets/works/apps/laz-dic/pad2.png",
      "/assets/works/apps/laz-dic/pad3.png",
      "/assets/works/apps/laz-dic/pad4.png",
      "/assets/works/apps/laz-dic/pad5.png",
    ],
  },
  { 
    name: "3hits", 
    icon: "/assets/works/apps/3hits/icon.png", 
    shortDescription: "Daily Planner & Habit Builder", 
    platform: "iOS", 
    isAvailable: false,
    description: "3hits is an easy-to-use daily planner app that you can complete three important things to do each day. Join the 8-bit world of 3hits and work on your top 3 things to do!",
    bulletPoints: {
      title: "Highlights:",
      content: [
        "Track your progress with the colors of three rings on calendar.",
        "View your target completion history in detail on calendar.",
        "Stay on act with motivational texts and target based 8-bit characters.",
      ]
    },
    tags: "iPhone, iOS, application, WatchOS, Apple, watch, app, mobile, to do, things, to-do, daily, planner, weekly, task, organize, plan, work, schedule, planning, motivation",
    url: null,
    iphoneScreenshots: [
      "/assets/works/apps/3hits/ss0.png",
      "/assets/works/apps/3hits/ss1.png",
      "/assets/works/apps/3hits/ss2.png",
      "/assets/works/apps/3hits/ss3.png",
    ],
    appleWatchScreenshots: [
      "/assets/works/apps/3hits/w-ss0.png",
      "/assets/works/apps/3hits/w-ss1.png",
      "/assets/works/apps/3hits/w-ss2.png",
      "/assets/works/apps/3hits/w-ss3.png",
    ],

  },

  // watchOS
  { 
    name: "Allegretto", 
    icon: "/assets/works/apps/allegretto/icon.png", 
    shortDescription: "Metronome", 
    platform: "watchOS", 
    isAvailable: false,
    description:
      "Allegretto offers you an effortless experience with its minimalistic and intuitive interface.",
    bulletPoints: {
      title: "Highlights:",
      content: [
        "Tempo syncing via bluetooth devices.",
        "Option to practice with tempo markings from slowest to fastest.",
        "Background mode.",
      ]
    },
    tags:"watchOS, Apple, watch, app, mobile, metronome, music, counter, beat, beats, metronom, rhythm, rythm, tempo, tempo player, tempo music",
    url: null,
    appleWatchScreenshots: [
      "/assets/works/apps/allegretto/ss0.jpg",
      "/assets/works/apps/allegretto/ss1.jpg",
      "/assets/works/apps/allegretto/ss2.jpg",
    ],
  },
  { 
    name: "wwiki", 
    icon: "/assets/works/apps/wwiki/icon.png", 
    shortDescription: "Search Wikipedia Articles", 
    platform: "watchOS", 
    isAvailable: true,
    description: "wwiki is the quickest way to access Wikipedia articles from your Apple Watch.",
    bulletPoints: {
      title: "Features:",
      content: [
        "Voice dictation.",
        "Language options."
      ]
    },
    tags: "watchOS, Apple, watch, app, mobile, wikipedia, wiki, article, information, quick, reference, search, vikipedi, wikipédia, education",
    url: "https://apps.apple.com/us/app/wwiki/id1527014954",
    appleWatchScreenshots: [
      "/assets/works/apps/wwiki/ss0.jpg",
      "/assets/works/apps/wwiki//ss1.jpg",
      "/assets/works/apps/wwiki/ss2.jpg",
    ],
  },
  { 
    name: "Istanbul Trafik", 
    icon: "/assets/works/apps/ist-trafik/icon.png", 
    shortDescription: "Traffic Density Tracking", 
    platform: "watchOS", 
    isAvailable: true,
    description: "İstanbul Trafik is an app for Apple Watch that offers watch complications to show you real-time traffic information provided by IBB.",
    tags: "watchOS, Apple, watch, app, mobile, traffic, density, trafik, istanbul, istanbul trafiği, navigasyon, yol tarifi, trafik app, trafik uygulaması",
    url: "https://apps.apple.com/us/app/i-stanbul-trafik/id1526870675",
    appleWatchScreenshots: [
      "/assets/works/apps/ist-trafik/ss0.jpg",
      "/assets/works/apps/ist-trafik/ss1.jpg",
      "/assets/works/apps/ist-trafik/ss2.jpg",
      "/assets/works/apps/ist-trafik/ss3.jpg",
    ],
  },

  // macOS
  { 
    name: "babydoll", 
    icon: "/assets/works/apps/babydoll/icon.png", 
    shortDescription: "Audio Plugin", 
    platform: "macOS" , 
    isAvailable: true,
    description: "An audio processor plugin for most popular DAWs: Logic Pro X and Ableton (macOS app with VST & AU plugins).",
    url: "https://github.com/u7k/babydoll",
    screenshots: [
      "/assets/works/apps/babydoll/1.png"
    ],
  }

]
