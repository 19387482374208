/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useFinderContext } from "../finder/Finder"

import { useDraggable } from "@neodrag/react"
import { useState, useRef, useEffect, useCallback } from "react"
import { useDoubleTap } from "use-double-tap"

export default function Window({ children }) {
  const { finderState, updateFinderState, isMediumScreen } = useFinderContext()
  const { isWindowFullscreened, isWindowOpened, windowTitle } = finderState.window

  const remToPx = (rem) => {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    )
  }

  const defaultWindowPosition = () => {
    return {
      x: remToPx(2.2),  //isMediumScreen ? remToPx(2.2) : 0,
      y: remToPx(1.5),
    }
  }

  // Update initial position
  const [position, setPosition] = useState(defaultWindowPosition)
  const positionRef = useRef(position)
  useEffect(() => {
    positionRef.current = position
  }, [position])

  // Store the last position.
  const [lastPosition, setLastPosition] = useState(defaultWindowPosition)
  const lastPositionRef = useRef(lastPosition)
  useEffect(() => {
    lastPositionRef.current = lastPosition
  }, [lastPosition])

  const isFullscreenedRef = useRef(isWindowFullscreened)
  useEffect(() => {
    isFullscreenedRef.current = isWindowFullscreened
  }, [isWindowFullscreened])

  const [isAnimatingFullscreen, setIsAnimatingFullscreen] = useState(false)

  const handleDrag = ({ offsetX, offsetY, domRect }) => {
    const newPosition = { x: offsetX, y: offsetY }
    setPosition(newPosition)

    // Check if the window is near the top for potential fullscreen entry.
    if (!isFullscreenedRef.current && domRect.top < 27) {
      // Start the fullscreen animation.
      setIsAnimatingFullscreen(true)
    }
  } //: handleDrag

  const toggleFullscreen = useCallback(() => {
    const newState = !isFullscreenedRef.current
    updateFinderState({ isWindowFullscreened: newState })

    if (newState) {
      // console.info("Window: Entering fullscreen.")
      setLastPosition(positionRef.current)
      setPosition({ x: 0, y: 0 })
    } else {
      // console.info("Window: Exiting fullscreen.")

      // If the current position's y is less than the initial position's y,
      // revert to the initial position.
      if (lastPositionRef.current.y < 1) {
        setPosition(defaultWindowPosition)
        return
      }
      setPosition(lastPositionRef.current)
    }
  }, [updateFinderState, positionRef, lastPositionRef])

  const handleDragEnd = useCallback(
    (data) => {
      const isFullscreened = isFullscreenedRef.current
      if (!isFullscreened && data.domRect.top < 27) {
        toggleFullscreen()
      } else if (isFullscreened && data.domRect.top > 28) {
        toggleFullscreen()
      }

      // Reset animation after drag ends.
      setIsAnimatingFullscreen(false)
    },
    [toggleFullscreen, isMediumScreen]
  )

  const draggableRef = useRef(null)
  const dragOptions = {
    axis: "both",
    bounds: { top: 26, bottom: 0, left: 0, right: 0 },
    disabled: isWindowFullscreened && !isMediumScreen,
    position: position,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,
    defaultPosition: defaultWindowPosition,
  }
  useDraggable(draggableRef, dragOptions)

  // Add resize listener
  useEffect(() => {
    function handleResize() {
      const newIsMediumScreen = window.matchMedia("(min-width: 768px)").matches

      if (isMediumScreen === newIsMediumScreen) {
        return
      }

        if (!newIsMediumScreen && !isFullscreenedRef.current) {
            // If screen size drops below md and not fullscreen, make it fullscreen.
            toggleFullscreen()
        } else if (newIsMediumScreen && isFullscreenedRef.current) {
            // If screen size goes above md and is fullscreen, exit fullscreen.
            toggleFullscreen()
        }
    }

     // Check initial screen size and set fullscreen if needed
    if (!window.matchMedia("(min-width: 768px)").matches && !isFullscreenedRef.current) {
      toggleFullscreen()
    }

    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [updateFinderState, isMediumScreen, toggleFullscreen])

  if (!isWindowOpened) {
    return null
  }
  return (
    <div
      className={`
        relative grid inset-0
        justify-items-center md:justify-items-start
        ${isWindowFullscreened ? "absolute pt-[--menu-bar-height]" : ""}
        `}
    >
      {/* This element serves as a background during the drag animation for entering fullscreen. */}
      {isAnimatingFullscreen && (
        <div
          className="
            absolute 
            inset-0
            w-screen 
            bg-black/50 
            border-dotted !border-[1.4px] !border-white
            z-[99]
            transition-opacity
            duration-300 
            ease-in-out"
          style={{
            height: `calc(100vh - var(--menu-bar-height))`,
          }}
        />
      )}

      <div
        className={`
            w-11/12 md:w-auto
            absolute
            bg-white       
            shadow-[3px_3px_rgba(0,0,0,0,1)]
            border-small border-black
            border-r-default
            z-[100]
            ${isAnimatingFullscreen ? "opacity-90 shadow-lg" : ""}
            ${
              isWindowFullscreened
                ? `
                w-screen 
                h-[calc(100vh-150px)]
                md:h-[calc(100vh-32px)]
                m-0 md:ml-0
                inset-0
                !top-0
                !left-0`
                : ""
            }
            `}
        ref={draggableRef}
      >
        <Toolbar
          title={windowTitle}
          closeWindow={() => updateFinderState({ isWindowOpened: false })}
          toggleFullscreen={toggleFullscreen}
          isFullscreened={isWindowFullscreened}
          isMediumScreen={isMediumScreen}
        />

        <WindowContent isFullscreened={isWindowFullscreened}>
          {children}
        </WindowContent>
      </div>
    </div>
  )
}

function Toolbar({ title, closeWindow, toggleFullscreen, isFullscreened, isMediumScreen }) {
  const onDoubleTap = useDoubleTap(() => {
    if (!isFullscreened || isMediumScreen) {
      toggleFullscreen()
    }
  })
  return (
    <div
      className={`
        relative flex flex-nowrap 
        ${isFullscreened && !isMediumScreen ? "cursor-default" : "cursor-move"}
        text-center
        text-black 
        border-b-default 
        pt-[1.5px] pr-[2px] 
        overflow-hidden
        group
        `}
      {...onDoubleTap}
    >
      <ToolbarShortLines />
      <ToolbarIcon
        icon="/assets/icons/window/x-mark.svg"
        iconWidth="11x"
        alt="Close window"
        onClick={closeWindow}
      />

      <ToolbarLongLines />

      <span
        className="
          inline 
          pl-[6px] pr-[4px] 
          text-center 
          select-none
          whitespace-nowrap
          "
      >
        {title}
      </span>

      <ToolbarLongLines />

      <ToolbarIcon
        icon="/assets/icons/window/enlarge.svg"
        alt="Toggle fullscreen"
        onClick={() => {
          if (!isFullscreened || isMediumScreen) {
            toggleFullscreen()
          }
        }}
        disabled={isFullscreened && !isMediumScreen}
      />

      <ToolbarShortLines />
    </div>
  )
} //: Toolbar

function ToolbarShortLines() {
  return (
    <div className="flex flex-col w-full max-w-[9px] my-auto mx-[3px]">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="inline-flex h-[1.6px] border-t-small p-[1px]"
        />
      ))}
    </div>
  )
} //: ToolbarShortLines

function ToolbarLongLines() {
  return (
    <div className="flex flex-col w-full my-auto mx-[3px]">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="inline-flex h-[1.6px] border-t-small p-[1px]"
        />
      ))}
    </div>
  )
} //: ToolbarLongLines

function ToolbarIcon({ icon, iconWidth, alt, onClick, disabled }) {
  return (
    <div className="flex flex-col relative my-auto mx-[3px] peer">
      <button
        className={`
            inline-flex 
            !w-[16px] !h-[16px]
            border-default 
            !cursor-pointer
            ${disabled ? "!cursor-default" : ""}
            `}
        onClick={onClick}
        disabled={disabled}
      >
        {!disabled && (
          <img
            src={icon}
            width={iconWidth ?? "12.5px"}
            className="
            m-auto
            opacity-[0] group-hover:opacity-100
            hover:contrast-200
            transition-opacity duration-150
            "
            alt={alt}
          />
        )}
      </button>
    </div>
  )
} //: ToolbarIcon

function WindowContent({ isFullscreened, children }) {
  return (
    <div
      className={`
        window-small
        max-w-auto md:max-w-screen-md xl:max-w-screen-xl
        resize-none md:resize
        max-h-[calc(100vh-200px)] md:max-h-[92vh]
        overflow-scroll
        bg-content-primary-bg
        ${
          isFullscreened
            ? `
           bg-white
            !max-w-full
            !max-h-full
            !resize-none
            !w-full
            !h-full
        `
            : ""
        }
        `}
      // style={resizingLimiter}
    >
      {children}
    </div>
  )
} //: WindowContent
