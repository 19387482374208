// Clock.jsx
import { useState, useEffect } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import "./retro-clock.css";

const cities = [
  { name: "Istanbul", offset: 3 },
  { name: "Paris", offset: 2 },
  { name: "London", offset: 1 },
  { name: "Tokyo", offset: 9 },
  { name: "New York", offset: -4 },
  { name: "Sydney", offset: 10 },
];

const getCityTime = (offset) => {
  // Calculate city time using UTC.
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60000;
  const cityTime = new Date(utc + 3600000 * offset);

  let hours = cityTime.getHours();
  const minutes = cityTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12;

  // Calculate offset difference relative to the local time.
  const localOffsetInHours = -(now.getTimezoneOffset() / 60);
  const offsetDiff = offset - localOffsetInHours;

  return {
    timeString: `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`,
    date: cityTime,
    offsetDiff,
  };
};

export default function WorldClock() {
  const [localTime, setLocalTime] = useState(new Date());
  const [currentTimes, setCurrentTimes] = useState(
    cities.map((city) => ({ ...city, ...getCityTime(city.offset) }))
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocalTime(new Date());
      setCurrentTimes(
        cities.map((city) => ({ ...city, ...getCityTime(city.offset) }))
      );
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full h-full lined-bg md:min-w-[499px]">
      {/* Local Time */}
      <div
        className="
          w-full p-4
          text-center whitespace-nowrap
          border-x-small border-b-small 
      "
      >
        <h1 className="text-2xl font-bold mb-0">Local Time</h1>
        <p className="text-5xl">{localTime.toLocaleTimeString()}</p>
      </div>

      <div
        className="
        grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
        gap-x-0 gap-y-0 border-x-small pb-0
      "
      >
        {currentTimes.map((city, index) => (
          <div
            key={city.name}
            className={`
              flex flex-row sm:!flex-col 
              text-left sm:text-center 
              justify-center items-center
            text-black pt-3 sm:pt-3 px-4 pb-[0.7rem]
              border-b-small
              ${index % 2 !== 1 ? "sm:border-r-small" : ""}
              ${index % 3 !== 2 ? "md:border-r-small" : ""}
            `}
          >
            {/* City Info */}
            <div className="flex flex-col sm:items-center w-full sm:w-auto pb-2 sm:pb-0 whitespace-nowrap">
              <p className="text-xl font-semibold">
                {city.name}
                {/* Display offset difference relative to local time */}
                {city.offsetDiff !== 0 && (
                  <span className="text-[1.15rem] font-light sm:hidden text-secondary-label">
                    {` (${
                      city.offsetDiff > 0
                        ? `+${city.offsetDiff}HRS`
                        : `${city.offsetDiff}HRS`
                    })`}
                  </span>
                )}
              </p>
              <p className="text-4xl sm:text-3xl">{city.timeString}</p>
            </div>

            {/* Clock */}
            <Clock
              className="retro-clock sm:mt-1 sm:mb-5"
              value={city.date}
              renderNumbers={true}
              renderHourMarks={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
