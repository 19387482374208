/*
    uygur.io
    Uygur Kıran - @u7k - 2023
 */

import { useState, useEffect } from 'react';
import { useFinderContext } from './Finder'
import { motion, useSpring, useMotionValue } from 'framer-motion';

import iconData from "./DesktopIconsData.json"

export default function Desktop({children}) {
  const { finderState, isMediumScreen } = useFinderContext()
  const { isWindowOpened, windowTitle } = finderState.window;

  return (
    <div>
      {children}
      <DesktopIcons 
        windowTitle={windowTitle} 
        windowOpened={isWindowOpened}
        isMediumScreen={isMediumScreen}
      />
    </div>
  );
}

function DesktopIcons({windowTitle, windowOpened, isMediumScreen}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const iconCount = iconData.length

  return (
    <div 
      className={`
        flex flex-col 
        md:!items-end 
    `}>
        <DesktopIconsRow> 
          {iconData.map((icon, idx) => (
            <DesktopIcon
              key={`icon-id-${idx}`}
              idx={idx}
              url={icon.url}
              imgUrl={icon.imgUrl}
              title={icon.title}
              isCurrentWindow={
                windowOpened &&
                (icon.title.toLowerCase() === windowTitle.toLowerCase())
              }
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              iconCount={iconCount}
              isMediumScreen={isMediumScreen}
            />
          ))}
        </DesktopIconsRow>
    </div>
  );
}

function DesktopIconsRow({children}) {
  return (
    <div className={`
      md:w-40 
      flex md:!flex-col 
      flex-1 md:gap-2

      mt-6 md:mt-4 

      bg-secondary-bg md:!bg-transparent
      rounded md:!rounded-none

      border-[2.2px] border-black md:!border-none
      shadow-sm md:!shadow-none

      mx-8 md:!mx-0
      !mb-4 md:!mb-auto
      pt-[8px] pb-0 
      px-[4px] md:!p-0

      fixed md:!relative 
      inset-x-0 md:!inset-auto 
      bottom-0 md:!bottom-auto
    `}>
      {children}
    </div>
  );
}

function DesktopIcon({
  idx,
  url,
  imgUrl,
  title,
  isCurrentWindow,
  hoveredIndex,
  setHoveredIndex,
  iconCount,
  isMediumScreen
}) {
    // Calculate the distance between the hovered icon and the current icon.
    const distance = hoveredIndex !== null ? Math.abs(hoveredIndex - idx) : null;
    const maxYOffset = -16

    // Create motion values for scale and y-position.
    const scaleValue = useMotionValue(1);
    const yValue = useMotionValue(0);

    // Configure spring animation settings.
    const springConfig = { stiffness: 500, damping: 30 };
    const springScale = useSpring(scaleValue, springConfig);
    const springY = useSpring(yValue, springConfig);

    // Update scale and y-position based on hover distance.
    useEffect(() => {
        if (isMediumScreen) {
            // Reset values for medium screens.
            scaleValue.set(1);
            yValue.set(0);
            return
        }
        
        if (distance === null) {
            // Reset values when no icon is hovered.
            scaleValue.set(1);
            yValue.set(0);
        }

        // Apply different scale and y-offset based on distance from hovered icon.
        if (distance === 0) {
            scaleValue.set(1.3)
            yValue.set(maxYOffset)
        } else if (distance === 1) {
            scaleValue.set(1.15)
            yValue.set(maxYOffset * 0.75)
        } else if (distance === 2) {
            scaleValue.set(1.05)
            yValue.set(maxYOffset * 0.5)
        } else {
            scaleValue.set(1)
            yValue.set(0)
        }
    }, [distance, scaleValue, yValue, isMediumScreen]);

    return (
        <motion.a
            onHoverStart={() => !isMediumScreen && setHoveredIndex(idx)}
            onHoverEnd={() => setHoveredIndex(null)}
            whileTap={!isMediumScreen && { y: -maxYOffset, scale: 0.92 }}
            href={url}
            className={`
                flex-1 items-center self-center text-center
                mb-2 md:mb-3 
                hover:bg-transparent hover:text-black 
                md:hover:invert
                ${!isMediumScreen ? "!z-[200]" : ""}
            `}
            style={{
                zIndex: distance !== null ? iconCount - distance : iconCount - idx,
            }}>
            <motion.div 
                className="relative inline-block" 
                style={{
                    scale: springScale,
                    y: springY,
                }}>
                <motion.img
                className={`
                    w-[2.25rem] md:w-[50px]
                    max-h-9 md:max-h-[52px]
                    mx-auto my-auto
                `}
                src={`${imgUrl}`}
                alt={`Icon for the ${title} page`}
                />
                {/* Indicator dot for current window. */}
                <motion.div className={`
                    absolute -top-[4px] -right-[6px]
                    w-[12px] h-[12px]
                    bg-red bg-blend-overlay
                    
                    border-primary-bg border-[1px] border-solid 
                    rounded-full 
                    transition-transform duration-300
                    ${isCurrentWindow ? "opacity-100" : "opacity-0" }
                    md:opacity-0
                    `}
                    animate={{
                        scale: !isCurrentWindow || isMediumScreen ? 0 : (distance === 0) ? 0.7 : 1,
                    }}
                    transition={{ duration: 0.03 }}
                    />
            </motion.div>

            <div className="h-[5px] md:h-1.8"/>

            {/* Icon title. */}
            <motion.p 
                className={`
                text-[0.9rem] md:text-[1rem]
                min-w-14 m-auto 
                px-0.5 pl-1 
                bg-none md:!bg-white
            `}
            style={{
                y: springY,
            }}>
                {title}
            </motion.p>
        </motion.a>
    )
} //: DesktopIcon
