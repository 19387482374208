/*
    uygur.io
    Uygur Kıran - @u7k - 2023
 */

import { Dropdown } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useFinderContext } from "../finder/Finder";
import AboutIoDialog from "./AboutIoDialog";

export default function MenuBar(props) {
  const { showDialog } = useFinderContext();

  const appleIcon = (
    <img
      src="/assets/icons/finder/apple.svg"
      className="
            h-[18px] w-[18px]
            align-center
        "
      alt="Apple menu bar icon"
    />
  );

  return (
    <MenuBarContainer>
      <MenuBarItem name="apple" icon={appleIcon}>
        <DropdownItem
          divider={true}
          onClick={() => {
            showDialog({
              title: null,
              content: <AboutIoDialog />,
              width: "360px",
              height: "auto",
              primaryButtonLabel: "OK",
            });
          }}
        >
          About Uygur.io
        </DropdownItem>

        <DropdownItem href="/clock">Clock</DropdownItem>
        <DropdownItem divider={true} href="/terminal">
          Terminal
        </DropdownItem>

        { isFullscreenAvailable() &&  
          <DropdownItem onClick={toggleFullScreen}>
            Full Screen
          </DropdownItem>
        }

        <DropdownItem href="/0">
          Turn Off
        </DropdownItem>
      </MenuBarItem>

      <WebsiteName />

      <MenuBarItem name="menu" title="Menu">
        <DropdownItem divider={true} href="/works">
          Works
        </DropdownItem>
        <DropdownItem divider={true} href="/about">
          About
        </DropdownItem>
        <DropdownItem divider={true} href="/contact">
          Contact
        </DropdownItem>
        <DropdownItem href="https://photography.uygur.io/">Photography</DropdownItem>
        {/* <DropdownItem href="/blog">Blog</DropdownItem> */}
      </MenuBarItem>

      <MenuBarItem name="links" title="Links">
        <DropdownItem
          divider={true}
          onClick={() => window.open("https://uygur.io/links/github", "_blank")}
        >
          Github
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            window.open("https://www.linkedin.com/in/uygurkiran/", "_blank")
          }
        >
          Linkedin
        </DropdownItem>
        {/* <DropdownItem
          onClick={() =>
            window.open("https://uygur.io/links/youtube", "_blank")
          }
        >
          Youtube
        </DropdownItem> */}
      </MenuBarItem>

      <MenuBarItem name="help" title="Help">
        {navigator.share && (
          <DropdownItem
            divider={true}
            onClick={() => {
              navigator
                .share({
                  title: document.title,
                  url: window.location.href,
                })
                .catch((error) => console.error("Error sharing:", error));
            }}
          >
            Share
          </DropdownItem>
        )}
        {/* <DropdownItem
          onClick={() => window.open("https://uygur.io/blog/rss", "_blank")}
        >
          Blog Feed
        </DropdownItem>
        <DropdownItem
          divider={true}
          onClick={() => window.open("https://photography.uygur.io/rss/", "_blank")}
        >
          Photo Feed
        </DropdownItem> */}
        <DropdownItem href="mailto:kiran@uygur.io">Send Mail</DropdownItem>
      </MenuBarItem>
    </MenuBarContainer>
  );
} //: MenuBar

function MenuBarContainer(props) {
  return (
    <nav
      className={`
          inset-0 !z-[2147483647] bg-primary-bg
          border-y-2 border-y-black
          h-[--menu-bar-height] w-screen`}
    >
      <div
        className={`
            flex flex-row 
            transition
            ml-[2px] md:ml-[4px]
            `}
      >
        {props.children}
      </div>
    </nav>
  );
} //: MenuBarContainer

function MenuBarItem(props) {
  let id = `${props.name}-dropdown-menu`;
  return (
    <Dropdown as={ButtonGroup} className="flex align-top justify-center">
      <DropdownToggle id={id} icon={props.icon} title={props.title} />

      <Dropdown.Menu
        className={`
                    absolute z-[2147483647] mt-[2.5px]
                    bg-primary-bg rounded-none
                    border-2 border-black border-l-3
                    shadow-[3px_3px_rgba(0,0,0,1)]
                    overflow-hidden p-0
                `}
        aria-labelledby={id}
      >
        {props.children}
      </Dropdown.Menu>
    </Dropdown>
  );
} //: MenuBarItem

function WebsiteName() {
  return (
    <a
      href="/"
      className={`
                text-center text-[1.2rem]
                py-0 px-[4px] my-auto ml-[7px]
                border-none rounded-0 
                cursor-pointer 
                after:content-[none]
                outline-none

                hover:bg-black hover:text-white
                focus:bg-black focus:text-white
                active:bg-black active:text-white
            `}
    >
      io
    </a>
  );
}

function DropdownToggle(props) {
  if (props.icon) {
    return (
      <Dropdown.Toggle
        as="a"
        href="#"
        id={props.id}
        role="button"
        className={`
                    text-center text-[1.2rem]
                    py-[1px] px-[7px] my-[1px] ml-[7px]
                    border-none rounded-0 
                    cursor-pointer 
                    after:content-[none]
                    outline-none

                    transition-filter duration-200 ease-linear
                 
                    hover:bg-white hover:invert
                    focus:bg-white focus:invert
                    active:bg-white active:invert
                `}
      >
        {props.icon}
      </Dropdown.Toggle>
    );
  }
  return (
    <Dropdown.Toggle
      as="a"
      href="#"
      id={props.id}
      className={`
                text-center text-[1.2rem]
                p-y0 px-[4px] my-auto mx-[7px]
                border-none rounded-0 
                cursor-pointer 
                after:content-[none]
                outline-none

                hover:bg-black hover:text-white
                focus:bg-black focus:text-white
                active:bg-black active:text-white
            `}
    >
      {props.title}
    </Dropdown.Toggle>
  );
} //: DropdownToggle

function DropdownItem(props) {
  return (
    <>
      <Dropdown.Item
        as="a"
        href={props.href ? props.href : "#"}
        className={`
                text-left text-[--link-color]
                py-[8px] px-[22px]
                transition duration-200
                hover:bg-black hover:text-white
            `}
        onClick={props.onClick}
      >
        {props.children}
      </Dropdown.Item>
      {props.divider && (
        <Dropdown.Divider className="m-0 border-t border-black border-dotted border-b-0 border-x-0" />
      )}
    </>
  );
} //: DropdownItem

function isFullscreenAvailable() {
  return document.fullscreenEnabled || 
    document.mozFullScreenEnabled || 
    document.webkitFullscreenEnabled || 
    document.msFullscreenEnabled;
}

function toggleFullScreen() {
  if (!document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement && 
      !document.webkitFullscreenElement && 
      !document.msFullscreenElement
    ) { // current working methods
      if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
  } else {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
      }
  }
}