/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import React, { useMemo } from 'react';

// const NEOFETCH_ASCII = `
//  __ ____ _____ ___ ______  (_)__ 
// / // / // / _ \`/ // / __/ / / _ \\
// \\_,_/\\_, /\\_, /\\_,_/_/ (_)_/\\___/
//     /___//___/   
// `;

// TODO: neofetch'teki apple logosu gibi çok renkli yap.
// const NEOFETCH_ASCII = `
// ░▒▓█▓▒░  ░▒▓██████▓▒░  
// ░▒▓█▓▒░ ░▒▓█▓▒░░▒▓█▓▒░ 
// ░▒▓█▓▒░ ░▒▓█▓▒░░▒▓█▓▒░ 
// ░▒▓█▓▒░ ░▒▓█▓▒░░▒▓█▓▒░ 
// ░▒▓█▓▒░ ░▒▓█▓▒░░▒▓█▓▒░ 
// ░▒▓█▓▒░ ░▒▓█▓▒░░▒▓█▓▒░ 
// ░▒▓█▓▒░  ░▒▓██████▓▒░  
// `;

const NEOFETCH_ASCII = `
░▒▓█▓▒░░▒▓█▓▒░ 
░▒▓█▓▒░░▒▓█▓▒░ 
░▒▓█▓▒░░▒▓█▓▒░ 
░▒▓█▓▒░░▒▓█▓▒░ 
░▒▓█▓▒░░▒▓█▓▒░ 
░▒▓█▓▒░░▒▓█▓▒░ 
 ░▒▓██████▓▒░  
`;

const ASCII_COLORS = [
  'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'
];

function getBrowserName() {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/edg/i)) return "Edge";
    if (userAgent.match(/chrome|chromium|crios/i)) return "Chrome";
    if (userAgent.match(/firefox|fxios/i)) return "Firefox";
    if (userAgent.match(/safari/i)) return "Safari";
    if (userAgent.match(/opr\//i)) return "Opera";
    return "Web Browser";
} //: getBrowserName
  
const SYSTEM_INFO = {
    "OS": "uygurOS",
    "Host": getBrowserName(),
    "Kernel": "React",
    "Uptime": "Always online",
    "Shell": "JavaScript",
    "Resolution": "Responsive",
    "Terminal": "ioTerm",
    "CPU": "Your device",
    "Memory": "Your browser's limit",
};

export default function Neofetch() {
    return (
      <div className="flex ml-3 mt-2 mb-1">
        <pre className="flex flex-col items-center align-center mr-5 mt-2">
          {NEOFETCH_ASCII.split("\n").map((line, index) => (
            <div
              key={index}
              style={{ color: ASCII_COLORS[index % ASCII_COLORS.length] }}
            >
              {line}
            </div>
          ))}
        </pre>

        <div className="text-[0.8rem] leading-[1.12rem]">
          <p>
            <span className="text-cyan font-bold">guest</span>@
            <span className="text-cyan font-bold">uygur.io</span>
          </p>
          <p className="leading-[1.14rem]">-------------------</p>

          {Object.entries(SYSTEM_INFO).map(([key, value], index) => (
            <div key={index}>
              <span className="text-yellow font-semibold">{key}</span>
              <span className="text-white">: {value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }