/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

const HELP_DICT = {
    "DATE": "Displays datetime",
    "CAL": "Displays the calendar",
    "KANYE": "Displays a pseudo-random Kanye West quote",
    "GENERATE": "Generates a random password",
    "UFETCH": "Displays system info",
    "----- ": " ", // empty newline
    "CLEAR": "Clears the terminal screen",
    "INFO": "Displays info about this terminal",
    // "BLOG":  "Opens the blog",
    // "REBOOT": "Reboots the uygur.io"
    "SHUTDOWN": "Shuts down the uygur.io"
}

export default function Help() {
  return (
    <table className="mb-3">
      <th colspan={"2"}>
        <p className="font-bold my-2 text-orange">Special commands listed:</p>
      </th>

      <tbody>
        {
          Object.entries(HELP_DICT).map(([key, value], index) => (
            <tr key={index}>
              <td className="font-bold whitespace-nowrap pr-4 text-yellow">{key}</td>
              <td className="font-thin">{value}</td>
            </tr> 
          ))
        }
      </tbody>
    </table>
  );
} //: help