/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { lightenColor } from './colorUtils'

export default function TagView({tag: {title, tintColor}}) {
  const fgColor = tintColor ?? "#C6C6C8"
  const bgColor = lightenColor(fgColor, 70)
  
  return (
    <span
      className={`
        inline-block
        rounded-full
        px-[0.7rem]
        py-[1px]
        text-xs
        font-light
        select-none
        !border
        !border-solid
        ${`!border-[${fgColor}]`}
      `}
      style={{ 
        backgroundColor: bgColor, 
        color: fgColor
      }}
    >
      {title}
    </span>
  )
}