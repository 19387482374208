/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useParams } from "react-router-dom";
import { appsDetailData } from "./appsDetailData";
import ImageSlider from "../../components/ImageSlider";

export default function AppDetail() {
  const params = useParams();

  const app = appsDetailData.find(
    (app) => app.name.toLowerCase().replace(/\s+/g, "-") === params.appName
  );

  if (!app) {
    throw new Response("Not Found", { status: 404, statusText: "Not found" });
  }

  return (
    <div className="bg-primary-bg w-full h-full">
      <div
        className="
          window-large
          max-w-[900px] mx-auto 
          pt-[2.1rem] pb-8 !px-[2rem]
        "
      >
        {/* Header Section */}
        <div className="pl-2.5 flex items-center space-x-6 sm:space-x-8">
          <img
            src={app.icon}
            alt={app.name}
            className="w-[100px] drop-shadow-md"
          />
          <div className="flex-1 text-left">
            <div className="ml-0.5 drop-shadow-sm mb-4">
              <h2 className="text-3xl font-bold mb-0 leading-1">{app.name}</h2>

              <p className="text-base font-normal leading-none">
                {app.shortDescription}
              </p>
            </div>

            <DownloadButton url={app.url} />
          </div>
        </div>

        <hr className="!mt-8" />

        {/* Description Section */}
        <DescriptionSection 
            description={app.description} 
            bulletPoints={app.bulletPoints} 
        />

        <hr className="m-0 !mb-8" />

        {/* Screenshots Section */}
        <div className="space-y-5">
          {app.iphoneScreenshots && (
            <ImageSlider
              title="iOS" 
              images={app.iphoneScreenshots}
              altText="iPhone Screenshot"
              addStyle="
                w-[156px] sm:w-[200px] md:w-[230px] 
                rounded-[12.8%/5.7%]
              "
            />
          )}
          {app.ipadScreenshots && (
            <ImageSlider
              title="ipadOS" 
              images={app.ipadScreenshots}
              altText="iPad Screenshot"
              addStyle="
                w-[200px] sm:w-[216px] md:w-[313px] 
                rounded-[1.9%/1.3%]
              "
            />
          )}
          {app.appleWatchScreenshots && (
            <ImageSlider
              title="watchOS" 
              images={app.appleWatchScreenshots}
              altText="Apple Watch Screenshot"
              addStyle="
                w-[156px] sm:w-[180px] md:w-[200px] 
                rounded-[16.8%/14.2%]
              "
            />
          )}
          {app.screenshots && (
            <ImageSlider
              images={app.screenshots}
              altText="Screenshot"
              addStyle="
                w-[200px] sm:w-[216px] md:w-[313px] 
                rounded-[1.9%/1.3%]
              "
            />
          )}
        </div>

        <hr className="mt-6 mb-10" />

      </div>
    </div>
  );
} //: AppDetail

function DownloadButton({ url }) {
  const isDisabled = !url;

  const buttonContent = () => {
    if (isDisabled)
      return {
        icon: "/assets/icons/window/arrow-down.svg",
        line1: null,
        line2: "Not Available",
      };
    if (url.startsWith("https://apps.apple"))
      return {
        icon: "/assets/icons/finder/apple.svg",
        line1: "Download on the",
        line2: "App Store",
      };
    if (url.startsWith("https://github.com"))
      return {
        icon: "/assets/icons/social/github.svg",
        line1: "View on",
        line2: "GitHub",
      };
    return {
      icon: "/assets/icons/window/arrow-down.svg",
      line1: null,
      line2: "Download Now",
    };
  };

  const { icon, line1, line2 } = buttonContent();

  return (
    <button
      disabled={isDisabled}
      onClick={() => {
        if (url) {
          window.open(url, "_blank", "noopener,noreferrer");
        }
      }}
      className={`main-button text-black font-sys !py-[0.3rem] !px-[0.7rem]
        ${isDisabled && "!opacity-50 hover:blur-[0.8px] transition select-none"}
        `}
    >
      <img src={icon} alt="" className="w-[21px] h-[21px] ml-1 mr-3" />
      <div className="text-left mr-[2px]">
        {line1 && (
          <p className="text-xs font-light leading-none m-0">{line1}</p>
        )}
        <p className="text-[1.05rem] font-bold leading-none m-0">{line2}</p>
      </div>
    </button>
  );
} //: DownloadButton

function DescriptionSection({ description, bulletPoints }) {
  return (
    <div className={
      `text-left text-base font-sans py-1 pl-0.5 
      ${!bulletPoints && "mb-2.5"}`
    }>
      <p className="whitespace-pre-line">{description}</p>
      {bulletPoints && (
        <div className="my-3">
          <h3 className="font-bold mb-1.5">{bulletPoints.title}</h3>
          <ul className="list-disc pl-5">
            {bulletPoints.content.map((point, idx) => (
              <li key={idx}>
                {point}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
} 
