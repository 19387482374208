/*
    uygur.io
    Uygur Kıran - @u7k - 2025
 */

import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

export default function Shutdown() {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="
      flex items-center justify-center
        w-full h-screen
        bg-coal-black
        p-2
        "
    >
      {showImage ? (
        <motion.img
          className="
            w-[50px] max-h-[52px]
          "
          src="/assets/icons/desktop/mac.svg"
          alt="Old Macintosh icon"
          animate={{ opacity: [0.7, 0, 0.7] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        />
      ) : (
        <p className="text-orange font-mono text-[0.82rem]">It's now safe to turn off your browser.</p>
      )}
    </div>
  );
}
