import React from "react";

export default function ImageSlider({ title, images, altText, addStyle }) {
  return (
    <div className="relative bg-white border-small">
      {title && (
        <div
          className="
            absolute 
            top-0 left-0 z-10 
            text-sm bg-black text-white 
            px-2 font-sys
            select-none
            rounded-br-md

           shadow-sm
          "
        >
          {title}
        </div>
      )}
      <div className="overflow-x-auto">
        <div className="flex space-x-4 px-4 py-4 mt-1 w-full">
          {images.map((src, idx) => (
            <img
              key={idx}
              src={src}
              alt={`${altText} ${idx + 1}`}
              className={`${addStyle} shadow-lg`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
