/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useState, useEffect } from 'react';

// import { useFinderContext } from '../../finder/Finder'
import { lightenColor } from '../../components/colorUtils'
import TagView from '../../components/TagView'
import { worksData } from "./worksData";
import { appsData } from "./appsData"
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import TitleView from '../../components/TitleView';

import { useAppsList } from '../../networking';

export default function Works() {
  // const { finderState, updateFinderState, isMediumScreen } = useFinderContext()
  // const { isWindowFullscreened, isWindowOpened, windowTitle } = finderState.window;

  return (
    <div
      className="
        window-large
        font-sans
        text-left
        leading-6
        bg-content-primary-bgu
        
     "
    >
      <section className="py-1.5 px-2">
        <table className="table-auto border-x-[1px] border-t-[1px] border-black w-full">
          {worksData.map((item, idx) => (
            <WorkItem key={idx} item={item} />
          ))}
        </table>
      </section>

      <section className="pt-4 pb-5 px-2">
        <div className="pl-[0.22rem] pb-0">
          <TitleView title="pet projects" />
        </div>
        <AppsView />
      </section>
    </div>
  );
} //: Works

function WorkItem({ item }) {
   const titleBgColor = item.tintColor;
   const subtitleBgColor = lightenColor(item.tintColor, 20);
   const bulletsBgColor = lightenColor(item.tintColor, 40);

  return (
    <tr>
      <td>
        <div className="flex flex-col">
          {/* Title */}
          <tr className="border-b leading-[1.625rem] px-2.5" style={{ backgroundColor: titleBgColor }}>
            <a
              href={item.externalUrl ?? ""}
              className="font-semibold cursor-pointer"
            >
              {item.title}
            </a>
          </tr>

          {/* Subtitle & Tags */}
          <tr className="border-b" style={{ backgroundColor: subtitleBgColor }}>
            <td className="px-2.5">
            <p className="flex items-center">
              {/* Subtitle */}
              <span className="text-sm mr-4 leading-[1.625rem]">{item.subtitle}</span>

              {/* Tags */}
              {item.tags && (
                <div className="flex items-center space-x-[0.525ch]">
                  {item.tags.map((tag, idx) => (
                    <TagView key={idx} tag={tag} /> 
                  ))}
                </div>
              )}
            </p>
            </td>
          </tr>

          {/* Description & Bullets */}
          <tr className="border-b px-2.5 py-[2px] pb-2.5" style={{ backgroundColor: bulletsBgColor }}>
            {/* Description */}
            <p className="text-sm py-1">{item.description}</p>

            {/* Bullets */}
            <ul className="list-disc text-sm pl-[16px]">
              {item.bullets.map((bullet, idx) => (
                <li key={idx} className="pb-[0.2rem]">
                  {bullet}
                </li>
              ))}
            </ul>
          </tr>
        </div>
      </td>
    </tr>
  );
} //: WorkItem

function useResponsiveColumns() {
  const [columns, setColumns] = useState(4); // Default columns

  useEffect(() => {
    function updateColumns() {
      const width = window.innerWidth;

      let newColNum
      if (width >= 768) {
        // md
        newColNum = 4
      } else {
        newColNum = 3
      }

      if (newColNum !== columns) {
        setColumns(newColNum)
      }
    } //: updateColumns

    window.addEventListener('resize', updateColumns);
    updateColumns(); // Initial check

    return () => window.removeEventListener('resize', updateColumns);
  }, [columns]);

  return columns;
} //: useResponsiveColumns

function AppsView() {
  const columns = useResponsiveColumns();

  // Group appsData into rows based on the number of columns
  const appsDataRows = [];
  for (let i = 0; i < appsData.length; i += columns) {
    appsDataRows.push(appsData.slice(i, i + columns));
  }

  return (
    <div className="space-y-4">
      {appsDataRows.map((rowApps, rowIndex) => (
        <div
          key={rowIndex}
          className="
            grid 
            gap-4 p-2 
            !border-[1px] border-dotted border-black w-full
            lined-bg
          "
          style={{
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'auto',
          }}
        >
          {rowApps.map((app, index) => (
            <AppItem key={index} app={app} />
          ))}
        </div>
      ))}
    </div>
  );
} //: AppsView

function AppItem({ app }) {
  const navigate = useNavigate();

  const generatePath = (appName) => {
    return `/works/apps/${appName.toLowerCase().replace(/\s+/g, '-')}`
  }

  return (
    <div className="text-center pt-1.5">
      <motion.button
        onClick={() => navigate(generatePath(app.name))}
        className="block w-16 h-16 mx-auto"
        whileHover={{ y: -5 }}
        transition={{ type: "spring", stiffness: 300, damping: 10 }}
      >
        <img
          className="w-full h-full object-cover filter drop-shadow-md hover:bg-transparent "
          src={app.icon}
          alt={app.name}
        />
      </motion.button>

      <div className="pt-2 pb-2 group">
        <a
          href={generatePath(app.name)}
          className="
              inline 
              text-center 
              font-semibold 
              !line-clamp-1
              drop-shadow-sm
              group-hover:!bg-transparent
          group-hover:!text-secondary-label
            "
        >
          <span
            className="
          text-blue
            underline
          group-hover:!text-secondary-label
           "
          >
            {app.name}
          </span>

          <p
            className="
            block 
            !text-sm 
            !font-light
            text-center 
            cursor-pointer 
            !line-clamp-1
          group-hover:text-secondary-label
          "
          >
            {app.shortDescription}
          </p>
        </a>

        <div className="block text-center space-x-[0.525ch] pt-[1.5px]">
          <TagView tag={{ title: app.platform }} />
        </div>
      </div>
    </div>
  );
}