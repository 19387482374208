/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import { useState, useEffect, useRef } from "react";
import { getOutput } from "./output"
import Neofetch from "./neofetch";
import '../../styles/terminal.css';

const CMD_END = "@io ~ % "

export default function Terminal() {
    const [user, setUser] = useState("guest")
    const [input, setInput] = useState("");
    const [history, setHistory] = useState([{ input: "ufetch", output: <Neofetch /> }]);
    const [lastCmd, setLastCmd] = useState("");
    const inputRef = useRef(null);
  
    useEffect(() => {
      inputRef.current.focus();
    }, []);

    function focusInput() {
      if (document.activeElement === inputRef.current) {
        return
      }
      inputRef.current.focus();
    };

    function handleInputChange(e) {
      setInput(e.target.innerText);
    }

    function handleKeyDown(e) {
      if (e.key !== "Enter") {
        return
      }
      e.preventDefault()
      const secureInput = input.replace(/[\r\n<>/{}()='"]/g, "")
      setInput("")
      inputRef.current.innerText = ""
      runCommand(secureInput);
      focusInput()
    } //: handleKeyDown

    function runCommand(input) {
      setLastCmd(input)
      const output = getOutput(input, setHistory)
      if (output === null) {
        setHistory([])
        return
      }
      setHistory([...history, { input, output }])
    } //: runCommand

    return (
      <div
        className={`
            window-large
            !w-full min-h-screen
            !h-full
            bg-black
            text-left
            text-secondary-bg
            py-2 px-3
            flex flex-col
            font-mono 
            text-sm leading-6
          `}
        onClick={focusInput}
      >
        <div className="flex-grow overflow-y-auto flex flex-col justify-start">
          <div>
            {history.map((item, index) => (
              <div key={index}>
                <div className="mt-2">
                  <span className="font-bold text-[#28FE14]">
                    {`${user}${CMD_END}`}
                  </span>
                  {item.input}
                </div>
                <div>{item.output}</div>
              </div>
            ))}
          </div>

          <div className="mt-2 mb-4 flex items-baseline">
            <span className="font-bold text-[#28FE14]">
              {`${user}${CMD_END}`}
            </span>
            <span
              ref={inputRef}
              contentEditable
              onInput={handleInputChange}
              onKeyDown={handleKeyDown}
              className="outline-none ml-[1ch]"
            ></span>
            {/* <span className="cursor"></span> */}

          </div>
        </div>
      </div>
    );
} //: Terminal
