/*
    uygur.io
    Uygur Kıran - @u7k - 2025
 */

    export default function DialogGroup({ children }) {
  return (
    <div
      className="
       bg-white 
        rounded-sm border-dotted
        overflow-y-auto
        flex flex-col 
        w-full h-full 
        min-h-[120px] max-h-[320px]
        px-2.5 py-2
        items-leading
       "
    >
      {children}
    </div>
  );
}
