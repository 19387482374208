/*
    uygur.io
    Uygur Kıran - @u7k - 2024
 */

import React from 'react';
import { lightenColor } from '../components/colorUtils'

export default function About() {
  return (
    <div
      className={`
          window-large
          font-sans
          text-left
   
          leading-6
          bg-content-primary-bg 
          py-1.5 px-2
      `}
    >
      <table className="table-auto border-x-[1px] border-t-[1px] border-black w-full">
        <AboutHeader tintColor="#F7F5F5" />

        <AboutSection title="Skills" tintColor="#C9E0B5">
          <AboutSectionItem title="Mobile App Development">
            <p>SwiftUI, UIKit, Cross-Platform Apps,</p>
            <p>Mobile Machine Learning and everything else 🍎</p>
            {/* <AboutSectionItemPS>
            (and everything else 🍎)
            </AboutSectionItemPS> */}

          </AboutSectionItem>
          <AboutSectionItem title="Web Development">
            <p>Go, JavaScript, Python,</p>
            <p>React, Tailwind, HTML5, CSS3,</p>
            <p>AWS, Docker, CI/CD etc.</p>

            <AboutSectionItemPS>
              (occasional homelabber)
            </AboutSectionItemPS>
          </AboutSectionItem>
          <AboutSectionItem title="Machine Learning">
            <p>Tensorflow, Keras, CoreML, Regressors, Classifiers, NLP</p>
            {/* <AboutSectionItemPS>
            (Experienced in integrating existing models <br/>and algorithms into applications.)
            </AboutSectionItemPS> */}
          </AboutSectionItem>
          <AboutSectionItem title="Other">
            <p>Agile, Test-driven Development</p>
          </AboutSectionItem>
        </AboutSection>

        <AboutSection title="Links" tintColor="#A2BFD9">
          <AboutSectionItem 
            title="Github" 
            subtitle={<a href="https://github.com/u7k">/u7k</a>}
          />
          <AboutSectionItem 
            title="Linkedin" 
            subtitle={<a href="https://www.linkedin.com/in/uygurkiran/">/uygurkiran</a>}
          />
        </AboutSection>

        <AboutSection title="Education" tintColor="#C4D1D7">
          <AboutSectionItem title="Istanbul Bilgi University">
            <p>Bachelor's Degree</p>
          </AboutSectionItem>
        </AboutSection>

        <AboutSection title="Languages" tintColor="#E3D8B7">
          <AboutSectionItem title="Turkish" subtitle="Native" />
          <AboutSectionItem title="English" subtitle="Fluent" />
          <AboutSectionItem title="French" subtitle="Intermediate" />
        </AboutSection>

        <AboutSection title="Hobbies" tintColor="#F7CCB2">
          <AboutSectionItem
            title="Photography"
          >
            <p>Analog, digital and instant photography</p>
          </AboutSectionItem>
          <AboutSectionItem
            title="Outdoor Activities"
          >
            <p>Enjoying nature and discovering new places</p>
          </AboutSectionItem>
          <AboutSectionItem title="Arts & Sciences">
            <p>
              Exploring the history and trends in arts, sciences, and technology
            </p>
          </AboutSectionItem>
          <AboutSectionItem title="Gardening">
            <p>Cultivating plants and designing outdoor spaces</p>
          </AboutSectionItem>
         
          <AboutSectionItem
            title="Music & Literature"
          >
            <p>Huge fan of Beethoven and Marcel Proust</p>
          </AboutSectionItem>
        </AboutSection>
      </table>
    </div>
  );
} //: About

function AboutHeader({tintColor}) {
    return (
      <div className="w-full border-b" style={{ backgroundColor: tintColor }}>
        <div
          className="
            flex flex-col sm:flex-row 
            items-center sm:items-start sm:justify-start
            px-4 pt-4 pb-8 sm:pb-6
            transition-all duration-300 ease-in-out
            space-x-5
        "
        >
          <img
            className="w-[130px] h-[130px] aspect-square"
            src="/assets/images/profile-picture.png"
            alt="Uygur's selfie"
          />

          <div
            className="
              flex flex-col 
              text-center sm:!text-left
              my-auto pt-2.5 sm:pt-[14px]
              mt-2 sm:mt-0
              space-y-4 sm:space-y-1
          ">
            <h1 className="text-2xl sm:text-3xl text-bold">Uygur Kıran</h1>
            <p className="text-sm italic">
              <em>
                optimist contrarian. developer. <br />
                animal person. husband. father.
              </em>
            </p>
          </div>
        </div>
      </div>
    );
} //: AboutHeader

function AboutSection({title, tintColor, children}) {
    const darkenedTintColor = lightenColor(tintColor, -100)

    return (
      <tr>
        <td>
          <div className="flex flex-col">
            <tr className="border-b" style={{ backgroundColor: tintColor }}>
              <td className="px-2.5 py-2">
                <h2 className="font-semibold text-[1.1rem] tracking-wide" style={{ color: darkenedTintColor }}>
                  {title.toUpperCase()}
                </h2>
              </td>
            </tr>
            {React.Children.map(children, (child, index) =>
              React.cloneElement(child, { tintColor, index })
            )}
          </div>
        </td>
      </tr>
    );
} //: AboutSection

function AboutSectionItem({title, subtitle, children, tintColor, index}) {
    const titleBgColor = lightenColor(tintColor, 20 + index * 10);
    const contentBgColor = lightenColor(tintColor, 40 + index * 10);

    return (
        <>
            <tr className="border-b" style={{ backgroundColor: titleBgColor }}>
                <td className="px-2.5">
                    <p className="flex items-center">
                        <span className="font-semibold mr-4 leading-[1.625rem]">{title}</span>
                        {subtitle && <span className="text-sm">{subtitle}</span>}
                    </p>
                </td>
            </tr>
            {children && (
                <tr className="border-b px-2.5 py-[2px]" style={{ backgroundColor: contentBgColor }}>
                    <td>
                        <ul className="list-disc text-sm pl-2">
                            {children}
                        </ul>
                    </td>
                </tr>
            )}
        </>
    )
} //: AboutSectionItem

function AboutSectionItemPS({children}) {
  return (
    <p className="text-sm text-secondary pb-2">
      {children}
    </p>
  )
}
