/*
    uygur.io
    Uygur Kıran - @u7k - 2025
 */

import DialogGroup from "./DialogGroup";
import { ClientVersion } from "../networking";

export default function AboutIoDialog() {
    function TableRow({ label, value }) {
        return (
          <tr>
            <th className="pr-[0.4rem] py-1 font-semibold text-right align-top w-[50%]">{label}</th>
            <td className="py-1 text-left align-top">{value}</td>
          </tr>
        );
    }

    return (
      <DialogGroup>
        <div 
          className="
            flex flex-col w-full h-full 
            items-center 
            justify-center 
            text-center
        ">
          <table 
            className="
              text-sm font-sans 
              mt-2 
              w-auto 
              text-center table-fixed
          ">
            <tbody>
              <TableRow label="Version" value={ClientVersion} />
              <TableRow label="Developer" value="Uygur Kıran" />
              <TableRow label="Built with" value="React & Tailwind " />
            </tbody>
          </table>

          <div className="
            flex flex-row items-baseline justify-center 
            mt-4 mb-1.5
            space-x-1.5
          ">
            <img
              src="/assets/images/made-w-mac.png"
              alt="Made with Macintosh"
              className="h-[30px]"
            />

            <img
              src="/assets/images/linux-powered.gif"
              alt="Linux powered"
              className="h-[31px]"
            />
          </div>

          <img
            src="/assets/images/uygur-logo.png"
            alt="Retro logo that says 'uygur'"
            className="h-[77px] ml-[0.425rem]"
          />

          <p className="text-secondary-label mt-1">© 2020-{new Date().getFullYear()}</p>

         
        </div>
      </DialogGroup>
    );
}